import { Formik } from "formik";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { closePopup } from "@/actions/popup";

import { formEvent, trackleadform } from "@/analytics/analytics";

import { createHubspotLead } from "@/client/OneistoxApiClient";

import CustomSelect from "@/design-system/Form/CustomSelect";
import PhoneInput from "@/design-system/Form/PhoneInput";

import { useAppDispatch, useAppSelector } from "@/hooks/hooks";
import useScreenDuration from "@/hooks/useScreenDuration";

import { PreviousRouteProvider } from "@/pages/_app";

import { getScrollPositionByPercentage, validatePhoneNumber } from "@/utils/utils";

import { theme } from "@/theme";

import Box from "../../Box";
import PrimaryButton from "../../Button/PrimaryButton";
import TextField from "../../Form/TextField";
import Icon from "../../Icon";
import Modal from "../../Modal";
import Typography from "../../Typography";

const RequestCallbackModalStyle = styled(motion.div)`
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: ${theme.colors.base.white};
  padding-top: 56px;
  padding-bottom: 56px;
  padding-left: ${theme.spacing(10)};
  padding-right: ${theme.spacing(10)};
  display: flex;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: 500px;
    padding-left: ${theme.spacing(8)};
    padding-right: ${theme.spacing(8)};
    height: auto;
    border-radius: ${theme.spacing(2)};
  }
`;

const { spacing } = theme;

type RequestCallBackProps = {
  isOpen: boolean;
  url?: string;
  courseName?: string;
};

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 14px;
  cursor: pointer;
  z-index: 1;
`;

const RequestCallbackPopup = ({ isOpen, courseName }: RequestCallBackProps) => {
  //const [OpenThankou, setOpenThankou] = useState(false);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [canSubmit, setCanSubmit] = useState(true);
  const [inputFlow, setInputFlow] = useState(null);
  const { previousRoute } = useContext(PreviousRouteProvider);
  const time = useScreenDuration();
  const scrollPercentage = getScrollPositionByPercentage();
  const loginDetails: any = useAppSelector((state) => state?.rootReducer?.auth);
  const isNotIndia = Boolean(loginDetails?.userLocation !== "INDIA");

  useEffect(() => {
    window.onbeforeunload = () => {
      if (inputFlow) {
        window.dataLayer?.push({
          event: "formAbandonment",
          eventCategory: "Form Abandonment",
          eventAction: inputFlow,
        });
        // const newDta = {
        //   pageReferrer: previousRoute,
        //   scrollPercentage,
        //   screenDuration: time.formattedTime,
        //   currentPage: window.location.href,
        //   pagePath: window.location.pathname,
        //   formId: "request_a_call_back",
        //   isLoggedIn: loginDetails.isAuthenticated,
        // };
        // formEvent("Form_abondonment", newDta);
      }
    };
  }, [inputFlow]);
  useEffect(() => {
    const handler = () => {
      if (inputFlow) {
        window.dataLayer?.push({
          event: "formAbandonment",
          eventCategory: "Form Abandonment",
          eventAction: inputFlow,
        });
        // const newDta = {
        //   pageReferrer: previousRoute,
        //   scrollPercentage,
        //   screenDuration: time.formattedTime,
        //   currentPage: window.location.href,
        //   pagePath: window.location.pathname,
        //   formId: "request_a_call_back",
        //   isLoggedIn: loginDetails.isAuthenticated,
        // };
        // formEvent("Form_abondonment", newDta);
      }
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [inputFlow]);
  // const handleThankyou = useCallback(() => {
  //   setOpenThankou(true);
  // }, []);

  // useEffect(() => {
  //   if (inputFlow) {
  //     const newDta = {
  //       pageReferrer: previousRoute,
  //       scrollPercentage,
  //       screenDuration: time.formattedTime,
  //       currentPage: window.location.href,
  //       pagePath: window.location.pathname,
  //       formId: "request_a_call_back",
  //       isLoggedIn: loginDetails.isAuthenticated,
  //     };
  //     formEvent("Form_start", newDta);
  //   }
  // }, [inputFlow]);
  const url = router?.asPath;

  const options = [
    {
      label: "Select Specialisation",
      value: "",
    },
    {
      label: "Architecture",
      value: "Architecture",
    },
    {
      label: "Interior Design",
      value: "Interior Design",
    },
    {
      label: "Civil Engineering",
      value: "Civil Engineering",
    },
    {
      label: "Mechanical Engineering",
      value: "Mechanical Engineering",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const optionsBim = [
    {
      label: "Select Specialisation",
      value: "",
    },
    {
      label: "Architecture",
      value: "Architecture",
    },
    {
      label: "Interior Design",
      value: "Interior Design",
    },
    {
      label: "Urban Design/Planning",
      value: "Urban Design/Planning",
    },
    {
      label: "Landscape Design",
      value: "Landscape Design",
    },
    {
      label: "Civil Engineering",
      value: "Civil Engineering",
    },
    {
      label: "Mechanical Engineering",
      value: "Mechanical Engineering",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const optionsCivil = [
    {
      label: "Select Specialisation",
      value: "",
    },
    {
      label: "Civil Engineering",
      value: "Civil Engineering",
    },
    {
      label: "Structural Engineering",
      value: "Structural Engineering",
    },
    {
      label: "Construction Management",
      value: "Construction Management",
    },
    {
      label: "Project Management",
      value: "Project Management",
    },
    {
      label: "Architecture",
      value: "Architecture",
    },
    {
      label: "Mechanical Engineering",
      value: "Mechanical Engineering",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  const optionsMcd = [
    {
      label: "Select Specialisation",
      value: "",
    },
    {
      label: "Architecture",
      value: "Architecture",
    },
    {
      label: "Interior Design",
      value: "Interior Design",
    },
    {
      label: "Urban Design/Planning",
      value: "Urban Design/Planning",
    },
    {
      label: "Landscape Design",
      value: "Landscape Design",
    },
    {
      label: "Civil Engineering",
      value: "Civil Engineering",
    },
    {
      label: "Mechanical Engineering",
      value: "Mechanical Engineering",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  let optionsFinal: { label: string; value: string }[];

  const workExperienceDropdownOptions: any = [
    { label: "0 (Student)", value: "0 (Student)" },
    { label: "Less than 1", value: "Less than 1" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9+", value: "9+" },
  ];

  const commonInitialValues = {
    email: "",
    fullname: "",
    phone: "",
    experience: "",
    specialisation: "",
  };

  const commonValidationObj = {
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter your email address."),
    fullname: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name.")
      .required("Please enter your full name."),
    phone: Yup.string()
      .test({
        test(value, ctx) {
          if (validatePhoneNumber(value!)) {
            return true;
          } else {
            return ctx.createError({
              message: "Enter a valid phone number",
            });
          }
        },
      })
      .required("Please enter a valid phone number.")
      .typeError("Enter a valid phone number"),
    specialisation: Yup.string().required("Please select your area of specialisation."),
  };

  let initialValues: {
      email: string;
      fullname: string;
      phone: string;
      specialisation: string;
      // designation?: string;
      // jobtitle?: string;
      // experience?: string;
    },
    validationObj;

  switch (courseName) {
  case "BIM":
    optionsFinal = optionsBim;
    initialValues = {
      ...commonInitialValues,
      // jobtitle: "", experience: ""
    };
    validationObj = {
      ...commonValidationObj,
      // jobtitle: Yup.string().required("Please select your jobtitle."),
      // experience: Yup.string().required("Please select your work experience"),
    };
    break;
  case "BIM_C":
    optionsFinal = optionsCivil;
    initialValues = {
      ...commonInitialValues,
      // jobtitle: "", experience: ""
    };
    validationObj = {
      ...commonValidationObj,
      // jobtitle: Yup.string().required("Please select your job title."),
      // experience: Yup.string().required("Please select your work experience"),
    };
    break;
  case "MCD":
    optionsFinal = optionsMcd;
    initialValues = {
      ...commonInitialValues,
      // jobtitle: "", experience: ""
    };
    validationObj = {
      ...commonValidationObj,
      // jobtitle: Yup.string().required("Please select your jobtitle."),
      // experience: Yup.string().required("Please select your work experience"),
    };
    break;
  default:
    optionsFinal = options;
    initialValues = {
      ...commonInitialValues,
      // jobtitle: "", experience: ""
    };
    validationObj = {
      ...commonValidationObj,
      // jobtitle: Yup.string().required("Please select your jobtitle."),
      // experience: Yup.string().required("Please select your work experience"),
    };
  }

  const isDisabled = (values: any, errors: any) => {
    const disabled =
      !!(!values.fullname || errors.fullname) ||
      !!(!values.email || errors.email) ||
      !!(!values.phone || errors.phone) ||
      !!(!values.specialisation || errors.specialisation);

    let disabledValue;

    switch (courseName) {
    case "BIM":
      disabledValue =
          disabled ||
          !!(!values.jobtitle || errors.jobtitle) ||
          disabled ||
          !!(!values.experience || errors.experience);
      break;
    case "MCD":
      disabledValue =
          disabled ||
          !!(!values.jobtitle || errors.jobtitle) ||
          disabled ||
          !!(!values.experience || errors.experience);
      break;
    case "BIM_C":
      disabledValue =
          disabled ||
          !!(!values.jobtitle || errors.jobtitle) ||
          disabled ||
          !!(!values.experience || errors.experience);
      break;
    }
    return disabledValue;
  };

  return (
    <Modal modalIsOpen={isOpen}>
      <RequestCallbackModalStyle
        className="modal"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        style={{ overflowY: "auto" }}
        transition={{ duration: 0.5 }}
      >
        <CloseButton
          onClick={() => {
            dispatch(closePopup());
            if (inputFlow != "") {
              window.dataLayer?.push({
                event: "formAbandonment",
                eventCategory: "Form Abandonment",
                eventAction: inputFlow,
              });
              // const newDta = {
              //   pageReferrer: previousRoute,
              //   scrollPercentage,
              //   screenDuration: time.formattedTime,
              //   currentPage: window.location.href,
              //   pagePath: window.location.pathname,
              //   formId: "request_a_call_back",
              //   isLoggedIn: loginDetails.isAuthenticated,
              // };
              // formEvent("Form_abondonment", newDta);
              setInputFlow("");
            }
          }}
        >
          <Icon size={20} type={"x-close"} varient={"solid"} />
        </CloseButton>

        {/* {OpenThankou ? (
          <Thankyou />
        ) : ( */}
        <Box width={"100%"}>
          <Typography varient="heading4" weightVarient="semibold" mb={spacing(2)}>
            Request a callback
          </Typography>

          <Typography color={"gray.600"} mb={spacing(10)}>
            to know how you can accelerate your career.
          </Typography>

          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              const splitName = values.fullname.split(" ");
              window.dataLayer?.push({
                event: "gtm.formSubmit",
                "gtm.elementId": `request_a_call_back_${courseName}`,
                "gtm.triggers": "",
              });
              setInputFlow("");
              const firstName = splitName.length > 0 ? splitName[0] : "";
              const lastName = splitName.length > 1 ? splitName.slice(1).join(" ") : "";
              const scrollPercentage = getScrollPositionByPercentage();
              const requestCallbackTrackObj = {
                name: values.fullname,
                email: values.email,
                mobile: values.phone,
                phone: values.phone,
                // jobtitle: values.jobtitle,
                // experience: values.experience,
                specialisation: values.specialisation,
                Page_path: window.location.pathname,
                Current_Page: window.location.href,
                Page_Referrer: previousRoute,
                percent_scrolled: scrollPercentage,
                on_screen_duration: time.formattedTime,
                loginDetails: loginDetails.userData,
                isLoggedIn: loginDetails.isAuthenticated,
              };
              const commonTrackleadObj = {
                name: values.fullname,
                email: values.email,
                mobile: values.phone,
                phone: values.phone,
                specialisation: values.specialisation,
                product: {
                  name: courseName || url,
                  url: url,
                  type: "certified-course",
                },
              };

              const commonTokensObj: {
                courseName: string;
                productUrl: string;
                lifecyclestage: string;
                phone: string;
                firstName: string;
                lastName: string;
                specialisation: string;
                designation?: string;
                // jobtitle?: string;
                // experience?: string;
              } = {
                courseName: courseName || url,
                productUrl: url,
                lifecyclestage: "lead",
                phone: String(values.phone),
                firstName: firstName,
                lastName: lastName,
                specialisation: values.specialisation,
              };

              let trackleadObj: any, tokenObj;

              switch (courseName) {
              case "BIM":
                trackleadObj = {
                  ...commonTrackleadObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                tokenObj = {
                  ...commonTokensObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                break;
              case "BIM_C":
                trackleadObj = {
                  ...commonTrackleadObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                tokenObj = {
                  ...commonTokensObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                break;
              case "MCD":
                trackleadObj = {
                  ...commonTrackleadObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                tokenObj = {
                  ...commonTokensObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                break;
              default:
                trackleadObj = {
                  ...commonTrackleadObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                tokenObj = {
                  ...commonTokensObj,
                  // jobtitle: values.jobtitle,
                  // experience: values.experience,
                };
                break;
              }

              try {
                // if (window) {
                //   // trackleadform("request_a_call_back", requestCallbackTrackObj);
                //   trackleadform(`request_a_call_back_${courseName}`, trackleadObj);
                // }
                const data = {
                  event_type: "call_back_form",
                  formUrl: window.location.href,
                  tokens: tokenObj,
                  extraData: {},

                  pageUrl: window.location.href,
                  landingPageUrl: sessionStorage.getItem("initial_landing_page"),
                  source: router?.query?.utm_source,
                  formId: `request_a_call_back_${courseName}`,
                };
                setCanSubmit(false);
                (async () => {
                  const res = await createHubspotLead(data, values.email);
                  setCanSubmit(true);
                  const url = "callback";
                  if (res.data) {
                    dispatch(closePopup());
                    if (isNotIndia) {
                      router.push(`/thank-you?url=${url}&row=true`);
                    } else {
                      router.push(`/thank-you?url=${url}`);
                    }
                  }
                })();
              } catch (error) {
                console.log(error);
              }
            }}
            validationSchema={Yup.object().shape(validationObj)}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              setTouched,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  id={`request_a_call_back_${courseName}`}
                  className={`formID__request_a_call_back_${courseName}`}
                >
                  <TextField
                    size="md"
                    placeholder="Full Name"
                    value={values.fullname}
                    onFocus={() => setInputFlow(inputFlow + "->fullname")}
                    error={errors.fullname && touched.fullname}
                    errorName={errors.fullname}
                    name="fullname"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="form_fullname"
                    className="formfield__form_fullname"
                  />

                  <PhoneInput
                    value={values.phone}
                    isIndia={!isNotIndia}
                    onChange={(value: string) => {
                      setFieldValue("phone", "+" + value);
                    }}
                    id="form_phone"
                    className="formfield__form_phone"
                    error={errors.phone && touched.phone}
                    errorName={errors.phone}
                    onFocus={() => setInputFlow(inputFlow + "->phone")}
                    fixedHeight={true}
                    name="phone"
                    onBlur={() => {
                      setTouched({ ...touched, phone: true });
                    }}
                  />

                  <TextField
                    size="md"
                    value={values.email}
                    error={errors.email && touched.email}
                    onFocus={() => setInputFlow(inputFlow + "->email")}
                    errorName={errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email address"
                    type="email"
                    name="email"
                    id="form_email"
                    className="formfield__form_email"
                  />

                  <Box mb={spacing(4)}>
                    {/* {courseName &&
                      ["BIM", "Home", "MCD"].includes(courseName) && (
                      <CustomSelect
                        placeholder="Select experience"
                        name="experience"
                        onFocus={() => {
                          window.dataLayer?.push({
                            event: "gtm.click",
                            "gtm.elementId": "form_experience",
                            "gtm.formId": "form_experience",
                          });
                          setInputFlow(inputFlow + "->experience");
                        }}
                        id="form_experience"
                        className="formfield__form_experience"
                        error={!!(errors.experience && touched.experience)}
                        errorName={errors.experience}
                        options={workExperienceDropdownOptions}
                        onChange={(value: string) => {
                          setFieldValue("experience", value);
                        }}
                        onBlur={() => {
                          setTouched({ ...touched, experience: true });
                        }}
                      />
                    )} */}
                    {/* {courseName &&
                      ["BIM", "Home", "MCD"].includes(courseName) && (
                      <TextField
                        size="md"
                        value={values.jobtitle}
                        error={errors.jobtitle && touched.jobtitle}
                        onFocus={() => setInputFlow(inputFlow + "->jobtitle")}
                        errorName={errors.jobtitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Job title"
                        name="jobtitle"
                        id="form_jobtitle"
                        className="formfield__form_jobtitle"
                      />
                    )} */}
                    <CustomSelect
                      placeholder="Select specialisation"
                      name="specialisation"
                      error={!!(errors.specialisation && touched.specialisation)}
                      errorName={errors.specialisation}
                      onFocus={() => {
                        window.dataLayer?.push({
                          event: "gtm.click",
                          "gtm.elementId": "form_specialisation",
                          "gtm.formId": "form_specialisation",
                        });
                        setInputFlow(inputFlow + "->specialisation");
                      }}
                      id="form_specialisation"
                      className="formfield__form_specialisation"
                      options={optionsFinal}
                      onChange={(value: string) => {
                        setFieldValue("specialisation", value);
                      }}
                      onBlur={() => {
                        setTouched({ ...touched, specialisation: true });
                      }}
                    />
                  </Box>
                  {/* {courseName === "BIM_C" && (
                    <TextField
                      size="md"
                      value={values.jobtitle}
                      error={errors.jobtitle && touched.jobtitle}
                      onFocus={() => setInputFlow(inputFlow + "->jobtitle")}
                      errorName={errors.jobtitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Job title"
                      name="jobtitle"
                      id="form_jobtitle"
                      className="formfield__form_jobtitle"
                    />
                  )} */}

                  <PrimaryButton
                    size="md"
                    fullWidth
                    label={canSubmit ? "Request a callback" : "Please wait..."}
                    className={"button__request_a_call_back"}
                    id="form_submit_button"
                    // disabled={
                    //   !!(!values.fullname || errors.fullname) ||
                    //   !!(!values.email || errors.email) ||
                    //   !!(!values.phone || errors.phone) ||
                    //   !!(!values.designation || errors.designation) ||
                    //   !!(!values.specialisation || errors.specialisation)
                    // }
                    disabled={isDisabled(values, errors) || !canSubmit}
                  />
                </form>
              );
            }}
          </Formik>
        </Box>
        {/* // )} */}
      </RequestCallbackModalStyle>
    </Modal>
  );
};

export default RequestCallbackPopup;
